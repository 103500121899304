<div class="appendix-container overflow-auto" #appendixSections *ngIf="!isLoading; else loaderRef" cdkScrollable>
  <div class="p-b-70">
    <ng-container *ngIf="sections?.length; else emptySections">
      <div class="appendix-section mt-2 w-80" cdkDropList (cdkDropListDropped)="emitDropSection($event)">
        <ng-container *ngFor="let section of sections; let i = index; let last = last">
          <div
            class="section-container section mt-3 bg-white"
            cdkDrag
            cdkDragBoundary=".appendix-section"
            (cdkDragStarted)="sectionDragStarted($event)"
            [cdkDragDisabled]="sections.length <= 1 || disableSectionsReorder() || !isEditAccess || false"
          >
            <div
              [ngStyle]="{ 'min-height.px': sectionDragPlaceholderHeight }"
              class="drag-custom-placeholder mt-2 border-radius-xlarge"
              *cdkDragPlaceholder
            ></div>

            <div class="title-container d-flex align-items-center justify-content-between mb-3">
              <div class="d-flex align-items-center">
                <div *ngIf="sections.length >= 2 && !disableSectionsReorder() && isEditAccess; else emptycontainerRef">
                  <mat-icon class="cursor-move txt-grey-secondary7 pointer" cdkDragHandle>drag_indicator</mat-icon>
                </div>
                <h3 class="f-16 f-w-600 l-h-28">{{ tabNumber }}.{{ i + 1 }}. {{ section.title }}</h3>
              </div>
              <div class="icon-groups" *ngIf="!isInvitedFirm && isEditAccess">
                <mat-icon class="f-20 pointer border-radius-full p-1 more-actions" [matMenuTriggerFor]="sectionsMenu">more_vert</mat-icon>
                <mat-menu #sectionsMenu="matMenu">
                  <button mat-menu-item (click)="emitOpenAppendixDrawer(true, true, i)"><mat-icon>edit</mat-icon>{{ 'Edit' | i18next }}</button>
                  <button mat-menu-item class="delete-action" (click)="emitDeleteSection(i)"><mat-icon>delete</mat-icon>{{ 'Delete' | i18next }}</button>
                </mat-menu>
              </div>
            </div>
            <div class="question-container" cdkDropList (cdkDropListDropped)="emitDropSubSection($event, i, j)">
              <ng-container *ngIf="section?.fields?.length">
                <ng-container *ngFor="let questions of section.fields; let j = index">
                  <div
                    class="question-section d-flex align-items-start justify-content-between section mt-2"
                    id="{{ 'field' + i + j }}"
                    cdkDrag
                    cdkDragBoundary=".question-container"
                    (cdkDragStarted)="subSectionDragStarted($event)"
                    [cdkDragDisabled]="section.fields?.length <= 1 || disableSubSectionsRorder(i) || !isEditAccess || false"
                  >
                    <div
                      [ngStyle]="{ 'min-height.px': questionDragPlaceholderHeight }"
                      class="drag-custom-placeholder m-t-12 border-radius-xlarge"
                      *cdkDragPlaceholder
                    ></div>

                    <div *ngIf="!disableSubSectionsRorder(i) && section?.fields?.length >= 2 && !section?.isEdited && isEditAccess">
                      <i class="material-icons cursor-move txt-grey-secondary7" cdkDragHandle> drag_indicator </i>
                    </div>

                    <div class="d-flex align-items-start w-100">
                      <div class="ml-2 w-100 bg-white">
                        <div class="d-flex justify-content-between mb-3 question-title">
                          <p class="f-14 f-w-600 l-h-28 txt-primary-01">{{ tabNumber }}.{{ i + 1 }}.{{ j + 1 }}. {{ questions.title }}</p>
                          <div class="d-flex align-items-center">
                            <ng-container *ngIf="questions.isActive && getDynamicCommentCount(questions?._id, commentCountList)">
                              <div class="d-flex mx-2 comment pointer" *ngIf="isEditAccess" (click)="emitOpenComments(i, j)">
                                <p>Comments</p>
                                <span>{{ getDynamicCommentCount(questions?._id, commentCountList) }}</span>
                              </div>
                            </ng-container>
                            <mat-slide-toggle
                              color="secondary"
                              class="mr-2"
                              *ngIf="!isLoading && isEditAccess"
                              [checked]="questions.isActive"
                              (change)="emitChangeActivationStatus(i, j)"
                              [disabled]="questions?.isNew"
                            ></mat-slide-toggle>
                            <div
                              *ngIf="isEditAccess"
                              class="mt-21"
                              (click)="isValidateEnabled(initalSections[i]?.fields[j]) ? emitValidateField(i, j) : ''; $event.stopPropagation()"
                            >
                              <conpulse-web-dynamic-form-validation-chip
                                class="mr-2"
                                [validationStatus]="questions.isValidated"
                              ></conpulse-web-dynamic-form-validation-chip>
                            </div>
                            <div class="question-icon" *ngIf="isEditAccess">
                              <mat-icon class="f-20 pointer border-radius-full p-1 more-actions subtitle-action" [matMenuTriggerFor]="questionsMenu"
                                >more_vert</mat-icon
                              >
                              <mat-menu #questionsMenu="matMenu">
                                <button mat-menu-item *ngIf="!isInvitedFirm" (click)="emitOpenAppendixDrawer(false, true, i, j)">
                                  <mat-icon>edit</mat-icon>{{ 'Edit' | i18next }}
                                </button>
                                <button
                                  mat-menu-item
                                  *ngIf="isValidateEnabled(initalSections[i]?.fields[j])"
                                  (click)="emitValidateField(i, j); $event.stopPropagation()"
                                >
                                  <mat-icon>check_circle</mat-icon>{{ 'Validate' | i18next }}
                                </button>
                                <button mat-menu-item (click)="emitOpenComments(i, j)" *ngIf="!questions.isNew">
                                  <mat-icon>chat</mat-icon>{{ 'Comment' | i18next }}
                                </button>
                                <button mat-menu-item *ngIf="!isInvitedFirm" class="delete-action" (click)="emitOnDelete(i, j)">
                                  <mat-icon>delete</mat-icon>{{ 'Delete' | i18next }}
                                </button>
                              </mat-menu>
                            </div>
                          </div>
                        </div>
                        <ng-container [ngSwitch]="questions.type">
                          <ng-container *ngSwitchCase="dynamicFieldDataTypes.QUILL" [ngTemplateOutlet]="QuillRef"></ng-container>
                          <ng-container *ngSwitchCase="dynamicFieldDataTypes.UPLOAD" [ngTemplateOutlet]="UploadRef"></ng-container>
                          <ng-container *ngSwitchCase="dynamicFieldDataTypes.TEMPLATE" [ngTemplateOutlet]="TemplateRef"></ng-container>
                        </ng-container>
                      </div>
                      <ng-template #QuillRef>
                        <div class="d-flex mb-3">
                          <div class="quill-height-200 msa-quill-height overflow-auto flex-1" [ngClass]="{ 'pointer-none': !isEditAccess }">
                            <form #editDescForm="ngForm" name="editDescForm">
                              <editor
                                [init]="initializeEditor('Enter ' + questions.title)"
                                [required]="true"
                                (onBlur)="questions.commonData.text = trimQuillValue(questions.commonData.text)"
                                [(ngModel)]="questions.commonData.text"
                                name="{{ 'quillEditor' + i + j }}"
                                id="{{ 'quill' + i + j }}"
                                (ngModelChange)="onValueChanges(i, j)"
                              >
                              </editor>
                            </form>
                          </div>
                          <div class="flex-1 diffbox ml-2 overflow-auto">
                            <conpulse-web-text-diff
                              [leftHandSide]="getPreviousValues(i, questions)"
                              [rightHandSide]="questions?.commonData?.text"
                            ></conpulse-web-text-diff>
                          </div>
                        </div>
                      </ng-template>
                      <ng-template #UploadRef>
                        <div class="upload-ref">
                          <label class="f-14 mt-3">{{ 'Document upload' | i18next }}</label>
                          <conpulse-web-file-input
                            [onlyDocumentUpload]="false"
                            [accept]="formatList"
                            [disabled]="!isEditAccess"
                            [fileLimit]="fileList"
                            [maxCount]="1"
                            [multiple]="false"
                            [document]="questions.commonData?.document"
                            (docUploaded)="onValueChanges(i, j)"
                            (download)="downloadDocument($event)"
                            [showDocViewBtn]="true"
                            (view)="viewDoc($event)"
                            [showDelete]="!isInvitedFirm && isEditAccess"
                          ></conpulse-web-file-input>
                        </div>
                      </ng-template>
                      <ng-template #TemplateRef>
                        <div class="w-100">
                          <div class="mb-4" #templateFormat>
                            <p class="mb-2 f-16 bold">{{ 'Template Format' | i18next }}</p>
                            <conpulse-web-file-input
                              [onlyDocumentUpload]="false"
                              [accept]="formatList"
                              [fileLimit]="fileList"
                              [maxCount]="1"
                              [multiple]="false"
                              [disabled]="!isEditAccess"
                              [document]="questions.commonData?.template"
                              (docUploaded)="onValueChanges(i, j)"
                              (download)="downloadDocument($event)"
                              [showDocViewBtn]="true"
                              (view)="viewDoc($event)"
                              [showDelete]="!isInvitedFirm && isEditAccess"
                            ></conpulse-web-file-input>
                          </div>
                          <div #templateValue>
                            <p class="mb-2 f-16 bold">{{ 'Template Value' | i18next }}</p>
                            <conpulse-web-file-input
                              [onlyDocumentUpload]="false"
                              [accept]="formatList"
                              [fileLimit]="fileList"
                              [maxCount]="1"
                              [multiple]="false"
                              [disabled]="!isEditAccess"
                              [document]="questions.commonData?.document"
                              (docUploaded)="onValueChanges(i, j)"
                              (download)="downloadDocument($event)"
                              [showDocViewBtn]="true"
                              (view)="viewDoc($event)"
                              [showDelete]="!isInvitedFirm && isEditAccess"
                            ></conpulse-web-file-input>
                          </div>
                        </div>
                      </ng-template>
                    </div>
                  </div>
                </ng-container>
              </ng-container>
            </div>
            <button
              *ngIf="!isInvitedFirm && isEditAccess"
              type="button"
              color="primary"
              class="mt-2"
              class="medium"
              mat-button
              (click)="emitOpenAppendixDrawer(false, false, i, section?.fields?.length ? j : 0)"
            >
              <mat-icon class="mat-20 pointer mr-1">add</mat-icon><span>{{ 'Add Question' | i18next }}</span>
            </button>
          </div>
        </ng-container>
        <div class="my-3" *ngIf="!isInvitedFirm && isEditAccess">
          <button
            type="button"
            color="primary"
            class="medium"
            mat-stroked-button
            (click)="emitOpenAppendixDrawer(true, false, section?.length ? i : 0, section?.fields?.length ? j : 0)"
          >
            <mat-icon class="mat-20 pointer mr-1">add</mat-icon><span>{{ 'Add Section' | i18next }}</span>
          </button>
        </div>
      </div>
    </ng-container>
  </div>
  <ng-template #emptySections>
    <div class="no-data">
      <div class="vector-image">
        <img [src]="notFoundImage" class="no-data" alt="" />
      </div>
      <h4 class="heading-empty mb-2">{{ "You don't have any data!" | i18next }}</h4>
      <p class="subtitle">{{ 'Click on Add section to add one.' | i18next }}.</p>
      <button
        *ngIf="!isInvitedFirm && isEditAccess"
        type="button"
        color="secondary"
        class="medium mt-1"
        mat-stroked-button
        (click)="emitOpenAppendixDrawer(true, false, section?.length ? i : 0, section?.fields?.length ? j : 0)"
      >
        <mat-icon class="mat-20 pointer mr-1">add</mat-icon><span>{{ 'Add Section' | i18next }}</span>
      </button>
    </div>
  </ng-template>
</div>
<ng-template #loaderRef>
  <div [ngClass]="{'p-20': !isFromSow }">
    <ng-container *ngFor="let values of [1, 2, 3, 4, 5]">
      <div class="skeleton-loader skeleton-title bold w-80 mb-4">{{ 'Dynamic fields' | i18next }}</div>
    </ng-container>
  </div>
</ng-template>
<div class="bottom-absolute pt-12 border-top bg-white justify-content-end d-flex align-items-center w-100 p-20" *ngIf="isEditAccess">
  <div class="pr-4" [ngClass]="{ 'mr-5': isFromSow }">
    <button mat-flat-button color="primary" class="ml-2 edit-button" [disabled]="isButtonDisabled() || checkSectionValues()" (click)="emitSaveDetails()">
      {{ 'Save' | i18next }}
    </button>
  </div>
</div>
