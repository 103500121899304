<!-- <p class="subtitle mb-2"> Region</p> -->
<form #filterSelect="ngForm">
  <mat-form-field class="full-width mb-12" floatLabel="never" [ngClass]="{ 'skeleton-loader w-100 skeleton-title h-auto': loading }">
    <mat-select
      disableOptionCentering
      name="select"
      [disabled]="isDisabled"
      [required]="isSelectMandatory"
      [placeholder]="matSelectPlaceHolder"
      (selectionChange)="doneSelect(matSelectedItems, false)"
      [(ngModel)]="matSelectedItems"
      #filterRef="ngModel"
      color="primary"
      [multiple]="isMultiple"
      (openedChange)="search('')"
    >
      <mat-form-field class="full-width mb-2" floatLabel="never">
        <mat-icon matPrefix>search</mat-icon>
        <input
          [placeholder]="matInputPlaceHolder"
          matInput
          name="input"
          [(ngModel)]="matSearchText"
          (keyup)="search(matSearchText)"
          (keydown)="$event.stopPropagation()"
        />
        <mat-icon matSuffix class="pointer" (click)="clearSearch()" [hidden]="check()">close</mat-icon>
      </mat-form-field>
      <p class="no-found" *ngIf="dropDownItems?.length === 0">No Results Found!</p>
      <mat-option
        *ngFor="let item of dropDownItems"
        [matTooltip]="item['parentName'] ? (item['parentName']) : ''"
        matTooltipPosition="above"
        [value]="item[uniqueField]"
        >{{ item[displayField] }}</mat-option
      >
    </mat-select>
    <mat-error *ngIf="filterRef.hasError('required')">{{requiredErrorMessage}}</mat-error>
  </mat-form-field>
</form>

<div *ngIf="!isWorkFlow; else workFlowChip">
  <div class="d-flex flex-wrap account-content mb-2" *ngIf="selectedDisplayItems?.length">
    <div class="tag-clouds p-1 border-line">
      <mat-chip-list>
        <mat-chip [matTooltip]="item['parentName'] ? (item['parentName']) : ''" matTooltipPosition="above" *ngFor="let item of selectedDisplayItems"
          >{{ item[displayField] }}
          <mat-icon [hidden]="isDisabled || loading" matChipRemove (click)="removeSelected(item[uniqueField])">
            close
          </mat-icon>
        </mat-chip>
      </mat-chip-list>
    </div>
  </div>
</div>
<ng-template #workFlowChip>
  <div class="mb-3" *ngIf="selectedDisplayItems?.length">
    <div *ngIf="!showMoreChips" class="mb-8">
      <mat-chip-list>
        <ng-container *ngFor="let item of selectedDisplayItems; let i = index">
          <ng-container *ngIf="i < 3 || showMoreChips">
            <mat-chip [matTooltip]="item['parentName'] ? (item['parentName']) : ''" matTooltipPosition="above" color="warn-color">
              {{ item[displayField] }}
              <mat-icon [hidden]="isDisabled || loading" matChipRemove (click)="removeSelected(item[uniqueField])">
                close
              </mat-icon>
            </mat-chip>
          </ng-container>
        </ng-container>
      </mat-chip-list>
    </div>
    <p class="p-title txt-secondary pointer ml-1 mt-2" *ngIf="selectedDisplayItems?.length > 3 && !showMoreChips" (click)="showMoreChips = true">
      +{{ selectedDisplayItems.length - 3 }} {{ 'more' | i18next }}
    </p>
    <div *ngIf="showMoreChips">
      <div class="tag-clouds mb-8" [class.border-line]="selectedDisplayItems?.length > 5">
        <mat-chip-list>
          <mat-chip
            [matTooltip]="item['parentName'] ? (item['parentName']) : ''"
            matTooltipPosition="above"
            class="mb-2"
            *ngFor="let item of selectedDisplayItems"
            >{{ item[displayField] }}
            <mat-icon [hidden]="isDisabled || loading" matChipRemove (click)="removeSelected(item[uniqueField])">
              close
            </mat-icon>
          </mat-chip>
        </mat-chip-list>
      </div>
      <p class="p-title txt-secondary pointer mt-1 ml-1 mb-2" *ngIf="selectedDisplayItems?.length > 3" (click)="showMoreChips = false">
        {{ 'Show less' | i18next }}
      </p>
    </div>
  </div>
</ng-template>
