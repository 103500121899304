import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { orderBy, uniqBy, groupBy, trim, get, isEqual, isEmpty } from 'lodash-es';
import { I18NextPipe } from 'angular-i18next';
import { Subscription } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import {
  UserService,
  ChatService,
  NotificationService,
  defaultProfilePhoto,
  ChatRoom,
  ChatMessage,
  user,
  ChatSoundType,
  UploadFileDetails,
  UploadType,
  UploaderService,
  CONSTANTS,
  chatType,
  DefaultGroupPhoto,
  AdminService,
  DeleteConfirmationComponent,
  rfpPdfLogoAllowedFileTypes,
  ApiSuccessResponse,
  ApiError,
  ViewType,
  noDataContent,
} from '@conpulse-web/core';
import { UtilityMethodsService } from '@conpulse-web/conpulse';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
@Component({
  selector: 'conpulse-web-chat-messages',
  templateUrl: './chat-messages.component.html',
  styleUrls: ['./chat-messages.component.scss'],
})
export class ChatMessagesComponent implements OnInit, OnChanges {
  @Input() conversation: ChatRoom;
  @Input() isFloatingChat: boolean = false;
  @Input() isProjectManagement: boolean = false;
  @Input() isReadOnly = false;
  @Output() triggerEditDeleteRoom: EventEmitter<string> = new EventEmitter();
  @Output() closeRoom: EventEmitter<void> = new EventEmitter();
  @Output() minimizeRoom: EventEmitter<void> = new EventEmitter();
  @Output() messageEdited: EventEmitter<ChatMessage> = new EventEmitter();
  @Output() updateConversationList = new EventEmitter();
  @ViewChild('messageContainer') messageContainerRef!: ElementRef;
  @ViewChild('emojiMenuTrigger') emojiTrigger: MatMenuTrigger;
  fileList;
  formatList;
  messageList = [] as ChatMessage[];
  groupedMessageList: any = {}; // TODO: To change any to Dictionary<ChatMessage[]>
  messagePayload = {
    page: 0,
    search: '',
    total: 0,
    limit: 10,
  };
  documentDetails = { size: 10 * 1024 * 1024, allowedFiles: '' };
  isPrivate = true;
  isLoading: boolean = false;
  defaultProfilePhoto = defaultProfilePhoto;
  defaultGroupPhoto = DefaultGroupPhoto;
  currentUser = {} as user;
  todayDate: string = '';
  yesterdayDate: string = '';
  document;
  type = chatType;
  editMessageId: string = '';
  initialMessage: string = '';
  isEqual = isEqual;
  sendMessageInitiated: boolean = false;
  imgUrl: string;
  noData: noDataContent = {} as noDataContent;
  _isEmpty = isEmpty;

  @ViewChild('chatMessageRef') chatMessageRef: ElementRef;

  // scrolling
  scrollDistance: number = 1;
  scrollThrottle: number = 1000;
  chatMessage;
  conversationList;
  isMessagedSending: boolean;
  incomingMessageSubscription: Subscription | null = null;
  onlineStatusSubscription: Subscription | null = null;
  documentSrc: string | ArrayBuffer;
  trim = trim;
  CONSTANTS = CONSTANTS;
  imgFormat = rfpPdfLogoAllowedFileTypes;
  baseUrl: string;

  constructor(
    private userService: UserService,
    private adminService: AdminService,
    private chatService: ChatService,
    private notificationService: NotificationService,
    private datePipe: DatePipe,
    private utility: UtilityMethodsService,
    private uploadService: UploaderService,
    private readonly i18nextPipe: I18NextPipe,
    public utilityService: UtilityMethodsService,
    private dialog: MatDialog
  ) {
    this.baseUrl = this.userService.s3PublicUrl;
  }

  ngOnInit(): void {
    this.noData = JSON.parse(JSON.stringify(CONSTANTS.NoDataContent));
    this.noData.content = 'No conversation has been made so far';

    this.computeRecentDates();
    this.imgUrl = this.userService.s3PublicUrl;
    this.loadAllowedFileFormats();
    if (!this.userService.currentUserInformation) {
      this.userService.getCurrentUserInformation().subscribe((data: user | any) => {
        this.currentUser = data;
        if (data.user) {
          this.currentUser = data.user;
        }
      });
    } else {
      this.currentUser = this.userService.currentUserInformation;
    }
    this.incomingMessageSubscription = this.chatService.incomingMessage$.subscribe({
      next: (chatMessage) => {
        if (chatMessage.roomId === this.conversation._id && chatMessage.createdBy['_id'] !== this.currentUser._id) {
          chatMessage?.isEdited || chatMessage?.isDeleted ? this.updateMessageInView(chatMessage) : this.addMessageToList(chatMessage);
        } else if (chatMessage.roomId !== this.conversation._id && chatMessage.createdBy['_id'] !== this.currentUser._id) {
          const message = {
            ...chatMessage,
            date: this.datePipe.transform(new Date(chatMessage.createdAt), 'mediumDate'),
            senderInfo: chatMessage.createdBy,
            roomInfo: this.conversation,
          };
          this.chatService.setChatMessageState(message);
        }
      },
    });
    this.onlineStatusSubscription = this.chatService.onlineStatus$.subscribe({
      next: ({ userId, status }) => {
        if (this.conversation?.chatProfile?._id === userId) {
          this.conversation.chatProfile.onlineStatus = status;
        }
      },
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.conversation) {
      this.messageList = [];
      this.chatMessage = '';
      this.groupedMessageList = {};
      this.messagePayload = {
        page: 0,
        search: '',
        total: 0,
        limit: 10,
      };
      if (this.conversation?._id) this.getMessageList();
    }
  }

  ngOnDestroy() {
    this.incomingMessageSubscription?.unsubscribe();
    this.onlineStatusSubscription?.unsubscribe();
  }

  /**
   * Loads allowed file formats from super Admin
   */

  loadAllowedFileFormats() {
    this.adminService.getFileFormat().subscribe((data) => {
      this.fileList = data.data.fileFormat;
      if (this.fileList?.length) {
        this.formatList = [];
        this.fileList.map((data) => {
          this.formatList.push(`.${data.value.fileFormat}`);
        });
        this.documentDetails.allowedFiles = this.formatList.join(',');
      }
    });
  }

  /**
   * Computes previous 1-2 dates
   */
  private computeRecentDates() {
    const today = new Date();
    this.todayDate = this.datePipe.transform(today, 'mediumDate') || '';
    today.setDate(today.getDate() - 1);
    this.yesterdayDate = this.datePipe.transform(today, 'mediumDate') || '';
  }

  /**
   * List the messages by conversationId
   */
  getMessageList(isInitial = false) {
    this.isLoading = true;
    this.chatService.getMessageList(this.messagePayload, this.conversation._id).subscribe(
      (data) => {
        this.messageList = this.messageList.concat(data.data?.messages);
        this.messagePayload.page = data.data?.paginationData?.page || 0;
        this.messagePayload.total = data.data?.paginationData?.total || 0;
        if (this.messageList?.length) this.groupMessageList(isInitial);
        this.refreshUnreadCount(!isInitial);
        this.isLoading = false;
      },
      (error) => {
        this.isLoading = false;
        this.notificationService.openErrorSnackBar(error?.message || error);
      }
    );
  }

  /**
   * Sort order for object key pipe
   * @returns 0
   */
  keyValueSort() {
    return 0;
  }

  /**
   * To enable bottom to top scrolling
   */
  scrollToBottom() {
    try {
      if (this.messageContainerRef?.nativeElement) {
        const element = this.messageContainerRef?.nativeElement as HTMLDivElement;
        element.scrollTop = element.scrollHeight;
      }
    } catch (err) {
      return;
    }
  }

  /**
   * Adds new message to the existing list
   * @param messageDetails message object
   */
  addMessageToList(messageDetails) {
    const message = {
      ...messageDetails,
      date: this.datePipe.transform(new Date(messageDetails.createdAt), 'mediumDate'),
      senderInfo: messageDetails.createdBy,
      roomInfo: this.conversation,
    };
    this.messageList = uniqBy(orderBy(this.messageList.concat([message]), ['createdAt'], ['desc']), '_id');
    if (this.groupedMessageList?.[this.todayDate]) {
      this.groupedMessageList[this.todayDate] = this.groupedMessageList[this.todayDate].concat([message]);
    } else {
      this.groupedMessageList[this.todayDate] = [message];
    }
    setTimeout(() => this.scrollToBottom(), 100);
    this.chatService.setChatMessageState(message);
    message.senderInfo._id === this.currentUser._id ? this.utility.playChatSound(ChatSoundType.OUTGOING) : this.utility.playChatSound(ChatSoundType.INCOMING);
  }

  /**
   * Sends message to the connected chat room
   */
  async sendMessage(event?) {
    if (!this.chatMessage.length && !this.document?.name) return;
    if (this.editMessageId.length > 0) return;
    if (event?.key === 'Enter' && !event?.shiftKey) event.preventDefault();
    const chatMessage = this.utility.parseUrlWithinString(this.chatMessage) || this.chatMessage;
    this.sendMessageInitiated = true;
    const document = this.document;
    if (this.document?.isNewUpload) {
      const documentUrl = await this.uploadDocumentToS3(document.file, document.fileExtension);
      document.key = documentUrl;
      document.isNewUpload = false;
    }
    if (this.chatMessage.trim().length || this.document) {
      this.chatMessage = '';
      this.document = {};
      this.isMessagedSending = true;
      this.chatService
        .sendNewMessage(
          this.conversation?._id
            ? { roomId: this.conversation?._id, message: chatMessage, document, projectId: this.conversation?.projectId }
            : { receiversId: this.conversation.chatProfile._id, message: chatMessage }
        )
        .subscribe((data) => {
          this.sendMessageInitiated = false;
          // To update room Id once room  has been created for the new chats
          if (!this.conversation?._id) {
            this.conversation._id = data.data.roomId;
          }
          this.addMessageToList(data.data);
          this.isMessagedSending = false;
          this.chatService.sendMessage(data.data?._id);
        }),
        (err) => {
          this.notificationService.openErrorSnackBar(this.i18nextPipe.transform(err.message));
        };
    }
  }

  blockChat(chatRoomId) {
    this.isLoading = true;
    const dialogRef = this.dialog.open(DeleteConfirmationComponent, {
      disableClose: true,
      width: '450px',
    });
    dialogRef.componentInstance.title = 'Block User';
    dialogRef.componentInstance.message = 'Are you sure you want to block the User?';
    dialogRef.componentInstance.acceptanceText = 'Yes';
    dialogRef.afterClosed().subscribe((confirmed) => {
      if (confirmed) {
        this.chatService.blockChat(chatRoomId).subscribe(
          (response: ApiSuccessResponse) => {
            this.isLoading = false;
            this.conversation.isBlocked = true;
            this.conversation['chatSettingsId'] = response?.data?.['roomSettings']?._id;
            this.chatService.updateBlockeduser(this.conversation);
            this.updateConversationList.emit(chatRoomId);
            this.notificationService.openSuccessSnackBar(response.data.message);
          },
          (error: ApiError) => {
            this.isLoading = false;
            this.notificationService.openErrorSnackBar(error?.message);
          }
        );
      }
    });
  }

  unBlockChat(chatRoomId, chatRoomSettingsId) {
    this.isLoading = true;
    const dialogRef = this.dialog.open(DeleteConfirmationComponent, {
      disableClose: true,
      width: '450px',
    });
    dialogRef.componentInstance.title = 'Unblock User';
    dialogRef.componentInstance.message = 'Are you sure you want to unblock the User?';
    dialogRef.componentInstance.acceptanceText = 'Yes';
    dialogRef.afterClosed().subscribe((confirmed) => {
      if (confirmed) {
        this.chatService.unBlockChat(chatRoomId, chatRoomSettingsId).subscribe(
          (response: ApiSuccessResponse) => {
            this.isLoading = false;
            this.conversation.isBlocked = false;
            this.updateConversationList.emit(chatRoomId);
            this.notificationService.openSuccessSnackBar(response.data.message);
          },
          (error: ApiError) => {
            this.isLoading = false;
            this.notificationService.openErrorSnackBar(error?.message);
          }
        );
      }
    });
  }

  /**
   * Triggers update message API call
   */
  editMessage(event?) {
    if (!this.chatMessage.length) return;
    if (event?.key === 'Enter' && !event?.shiftKey) event.preventDefault();
    const chatMessage = this.utility.parseUrlWithinString(this.chatMessage) || this.chatMessage;
    if (chatMessage.trim().length && !isEqual(trim(chatMessage), this.initialMessage)) {
      this.chatMessage = '';
      this.isMessagedSending = true;
      this.chatService.updateMessage({ _id: this.editMessageId, message: chatMessage }).subscribe((data) => {
        this.focusout();
        this.updateMessageInView(data.data);
        this.isMessagedSending = false;
        this.chatService.sendMessage(data.data?._id);
      }),
        (err) => {
          this.notificationService.openErrorSnackBar(this.i18nextPipe.transform(err.message));
        };
    }
  }

  /**
   * Updates message in view component
   */
  updateMessageInView(messageDetails: ChatMessage) {
    const message = {
      ...messageDetails,
      date: this.datePipe.transform(new Date(messageDetails.createdAt), 'mediumDate'),
      senderInfo: messageDetails.createdBy,
      roomInfo: this.conversation,
    };
    this.chatService.setChatMessageState(message);
    const messageIndex = this.messageList.findIndex((messageData) => messageData._id === message._id);
    if (messageIndex > -1) {
      this.messageList[messageIndex] = message;
      const groupMessageIndex = this.groupedMessageList[message.date].findIndex((messageData) => messageData._id === message._id);
      if (groupMessageIndex > -1) {
        this.groupedMessageList[message.date][groupMessageIndex] = message;
      }
    }
  }

  /**
   * Groups messages into respective dates as buckets
   */
  private groupMessageList(isInitial) {
    const oldScrollHeight = this.messageContainerRef?.nativeElement?.scrollHeight;
    this.messageList = orderBy(
      uniqBy(
        this.messageList.map((chatMessage) => {
          const dateString = chatMessage.createdAt.length ? this.datePipe.transform(new Date(chatMessage.createdAt), 'mediumDate') || '' : '';
          return {
            ...chatMessage,
            date: dateString,
          };
        }),
        '_id'
      ),
      'createdAt'
    );
    this.groupedMessageList = groupBy(this.messageList, 'date');
    if (isInitial) {
      setTimeout(() => this.scrollToBottom(), 100);
    } else {
      setTimeout(() => {
        const newScrollHeight = this.messageContainerRef?.nativeElement?.scrollHeight;
        const scrollHeightDiff = newScrollHeight - oldScrollHeight;
        this.messageContainerRef.nativeElement.scrollTop = scrollHeightDiff;
      }, 100);
    }
  }

  /**
   * To fetch next set of list
   */
  onScroll() {
    if (this.messageList.length < this.messagePayload.total) {
      this.messagePayload.page++;
      this.getMessageList();
    }
  }

  refreshUnreadCount(forceRefresh: boolean = false) {
    if (this.conversation?._id) {
      forceRefresh
        ? this.chatService.getMessageList(this.messagePayload, this.conversation._id).subscribe({
            next: () => {
              this.chatService.refreshUnreadCount();
              this.chatService.refreshRoomUnreadCount(this.conversation._id || '');
            },
          })
        : (this.chatService.refreshUnreadCount(), this.chatService.refreshRoomUnreadCount(this.conversation._id || ''));
    }
  }

  /**
   * Handles uploaded document
   */
  uploadDocument(event) {
    this.chatMessage = '';
    const inputElement = event.target as HTMLInputElement;
    const fileList = inputElement.files[0];
    const fileExtension = fileList.name.substring(fileList.name.lastIndexOf('.'), fileList.name.length);
    if (isEmpty(this.formatList)) {
      this.notificationService.openSuccessSnackBar(this.i18nextPipe.transform('File format not set please contact consource team'));
    }
    const fileSupported = this.formatList.includes(fileExtension);
    const maxFileSize = this.fileList.find((ele) => ele.value.fileFormat === fileExtension.split('.')[1])?.value?.fileSize;
    if (!fileSupported) {
      this.notificationService.openErrorSnackBar(`Unable to upload ${fileList.name} since it is not of allowed file formats`);
      return;
    } else if (fileList.size > maxFileSize * Math.pow(10, 6)) {
      this.notificationService.openErrorSnackBar(`Total size of the file ${fileList.name} should be less than ${Math.round(maxFileSize)} MB`);
    } else {
      const data = {
        file: fileList,
        type: 2,
        name: fileList.name,
        isNewUpload: true,
        key: undefined,
        fileExtension: fileExtension,
      };
      this.document = data;
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.documentSrc = e.target.result;
      };
      reader.readAsText(fileList);
    }
  }

  /**
   * Uploads documents to S3
   */
  async uploadDocumentToS3(file: File, extension: string) {
    const fileName = `${uuidv4()}`;
    const mimeType = file.type;
    const fileDetails: UploadFileDetails = {
      uploadType:
        this.currentUser.role === 5
          ? UploadType.SA_CHAT
          : CONSTANTS.CLIENT_USER_ROLES.includes(this.currentUser.role)
          ? UploadType.CLIENT_USER_CHAT
          : UploadType.PROVIDER_USER_CHAT,
      fileKey: `${fileName}${extension}`,
      mimeType: mimeType,
    };
    const referenceIds = { roomId: this.conversation?._id };
    if (fileDetails.uploadType === UploadType.CLIENT_USER_CHAT) referenceIds['clientId'] = this.currentUser.companyId['_id'];
    if (fileDetails.uploadType === UploadType.PROVIDER_USER_CHAT) referenceIds['providerId'] = this.currentUser.firmId['_id'];
    const urls = get(await this.uploadService.getUploadUrl(fileDetails, referenceIds).toPromise(), 'data', null);
    const uploadUrl: string = urls?.uploadUrl;
    if (uploadUrl) {
      try {
        await this.uploadService.uploadToPresignedUrl(uploadUrl, file, mimeType).toPromise();
        return urls.filePath;
      } catch (err) {
        this.notificationService.openErrorSnackBar('Could not upload image!');
      }
    } else {
      this.notificationService.openErrorSnackBar('Could not upload image!');
    }
  }
  /**
   * Closes/minimizes the chat room
   */
  closeOrMinimizeChatRoom(isMinimize: boolean = false) {
    isMinimize ? this.minimizeRoom.emit() : this.closeRoom.emit();
  }

  /**
   * Downloads the document
   * @param documentKey document unique identifier
   */
  async viewDocument(documentKey: string) {
    try {
      const response = await this.uploadService.getViewUrl({ responseType: ViewType.VIEW, fileKey: documentKey }).toPromise();
      window.open(response?.data?.viewUrl);
    } catch (error) {
      this.notificationService.openErrorSnackBar(`Couldn't download the document`);
    }
  }

  /**
   * Downloads the document
   * @param documentKey document unique identifier
   */
  async downloadDocument(fileKey: string, fileName: string) {
    this.uploadService.openDocument({ responseType: ViewType.DOWNLOAD, fileKey, fileName });
  }

  isPreviewAvailable(documentKey: string) {
    return this.utilityService.isPreviewAvailable(documentKey);
  }

  /**
   * Updates edit message variables to selected message to be edited
   */
  triggerEditMessage(message: ChatMessage) {
    this.editMessageId = message._id;
    this.initialMessage = message.message;
    this.chatMessage = this.initialMessage;
    this.focusAndScrollTextarea();
  }

  /**
   * Triggers update message API call
   */
  focusAndScrollTextarea() {
    this.chatMessageRef.nativeElement.focus();
    setTimeout(() => {
      const textarea = this.chatMessageRef.nativeElement;
      textarea.scrollTop = textarea.scrollHeight;
    });
  }

  /**
   * On Focus out of Input box
   */
  focusout() {
    this.editMessageId = '';
    this.chatMessage = '';
    this.initialMessage = '';
  }

  /**
   * Triggers Delete confirmation
   */
  triggerDeleteMessage(messageId: string) {
    const dialogRef = this.dialog.open(DeleteConfirmationComponent, {
      disableClose: true,
      width: '450px',
    });
    dialogRef.componentInstance.title = 'Delete';
    dialogRef.componentInstance.message = 'Are you sure you want to delete?';
    dialogRef.componentInstance.acceptanceText = 'Yes';
    dialogRef.afterClosed().subscribe((confirmed) => {
      if (confirmed) {
        this.deleteMessage(messageId);
      }
    });
  }

  /**
   * Calls Delete Message API
   */
  deleteMessage(messageId: string) {
    this.isMessagedSending = true;
    this.chatService.deleteMessage(messageId).subscribe((data) => {
      this.updateMessageInView(data.data);
      this.isMessagedSending = false;
      this.chatService.sendMessage(data.data?._id);
    }),
      (err) => {
        this.notificationService.openErrorSnackBar(this.i18nextPipe.transform(err.message));
      };
  }

  addEmoji(event) {
    const { chatMessage } = this;
    const selectionStart = this.chatMessageRef.nativeElement.selectionStart;
    const text = `${chatMessage.substring(0, selectionStart)}${event.emoji.native}${chatMessage.substring(selectionStart)}`;
    this.chatMessage = text;
    this.emojiTrigger.closeMenu();
    this.chatMessageRef.nativeElement?.focus();
  }

  getFileName(str) {
    if (str.length >= 18) return str.substr(0, 7) + '...' + str.substr(-7);
    return str;
  }
}
